import React from 'react';
import HomeLayout from '../layouts/home';
import { Link, graphql } from 'gatsby';

import styles from './index.module.css';

export default function Home({ data }) {
  return (
    <HomeLayout>
      {data.allMarkdownRemark.edges.map(({ node }) => (
        <div key={node.id}>
          <Link to={`/blog${node.fields.slug}`} className={styles.entryLink}>
            <div className={styles.entryTitle}>{node.frontmatter.title}</div>
            <div className={styles.entryDate}>{node.frontmatter.date}</div>
          </Link>
        </div>
      ))}
    </HomeLayout>
  );
}

export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: frontmatter___date, order: DESC }) {
      edges {
        node {
          frontmatter {
            title
            date
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;

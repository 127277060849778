import React from 'react';
import Footer from '../components/footer';
import Header from '../components/header';

import styles from './home.module.css';

export default function HomeLayout({ children }) {
  return (
    <div className={styles.home}>
      <Header />
      <div className={styles.content}>{children}</div>
      <Footer />
    </div>
  );
}
